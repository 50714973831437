import React, { Component, Fragment } from 'react'
import {Navbar,Container, Row, Col,Button, Nav, NavDropdown} from 'react-bootstrap';
import MainLogo from './MainLogo';

class Menu extends Component {
  render() {
    return (
      <Fragment>
        <section className="theme_menu stricky">
<Navbar bg="light" expand="lg" className='main-navbar'>
  <Container className="menu-bg">
     <Row>
      <Col className='menu-column'>
    <Navbar.Toggle aria-controls="basic-navbar-nav main-menu" />
    <Navbar.Collapse id="basic-navbar-nav" className="main-menu menu-mobile">
      <Nav className="ms-auto menu">
        <Nav.Link href={process.env.PUBLIC_URL + '/'} className="home"><span class="fa fa-home"></span></Nav.Link>   
        <Nav.Link href={process.env.PUBLIC_URL + '/about-us'}>About Us</Nav.Link>   
        <Nav.Link href="https://thedoctorsiea.in/" target="_blank">MBBS in Russia</Nav.Link>    
        <NavDropdown title="Prominent Medical Universities" id="basic-nav-dropdown" className='main-navbar-dropdown submenu'>
      	<NavDropdown.Item href="https://studymedicalmbbsinrussia.co.in/rostov-state-medical-university/" target="_blank">Rostov State Medical University</NavDropdown.Item>
	<NavDropdown.Item href="https://studymedicalmbbsinrussia.co.in/kazan-state-medical-university/" target="_blank">Kazan State Medical University</NavDropdown.Item>
	<NavDropdown.Item href="https://studymedicalmbbsinrussia.co.in/kazan-federal-university/" target="_blank">Kazan Federal University</NavDropdown.Item>
	<NavDropdown.Item href="https://studymedicalmbbsinrussia.co.in/kursk-state-medical-university/" target="_blank">Kursk State Medical University</NavDropdown.Item>
	<NavDropdown.Item href="https://studymedicalmbbsinrussia.co.in/crimea-federal-university/" target="_blank">Crimea Federal University</NavDropdown.Item>
	<NavDropdown.Item href="https://studymedicalmbbsinrussia.co.in/north-ossetian-state-medical-academy/" target="_blank">North Ossetian State Medical Academy</NavDropdown.Item>
	<NavDropdown.Item href="https://studymedicalmbbsinrussia.co.in/peoples-friendship-university/" target="_blank">Peoples&#8217; Friendship University</NavDropdown.Item>
	<NavDropdown.Item href="https://studymedicalmbbsinrussia.co.in/stavropol-state-medical-university/" target="_blank">Stavropol State Medical University</NavDropdown.Item>
        </NavDropdown>

        <NavDropdown title="Useful Links" id="basic-nav-dropdown" className='main-navbar-dropdown submenu'>    
		  
        <NavDropdown.Item href={process.env.PUBLIC_URL + '/about-russia/'}>About Russia &#038; Medical Studies</NavDropdown.Item> 
        <NavDropdown.Item href={process.env.PUBLIC_URL + '/admission-process/'}>Admission Process for Russia</NavDropdown.Item> 
        <NavDropdown.Item href={process.env.PUBLIC_URL + '/faq-for-mbbs-in-russia-by-indians/'}>Frequently Asked Questions</NavDropdown.Item> 
	<NavDropdown.Item href={process.env.PUBLIC_URL + '/russian-language-course/'}>Russian Language Course</NavDropdown.Item> 
         </NavDropdown>
      
        <Nav.Link href="https://thedoctorsiea.in/" target="_blank">MBBS Fee Structure</Nav.Link>  
<Nav.Link href="https://thedoctorsiea.in/apply-online/" target="_blank">Apply Online</Nav.Link>  
<Nav.Link href={process.env.PUBLIC_URL + '/top-mbbs-in-abroad-consultants-in-delhi/'}>Contact Us</Nav.Link>  

      </Nav>
    </Navbar.Collapse>
    </Col>
    </Row>   
  </Container>
</Navbar>
</section>
               </Fragment>
    )
  }
}

export default Menu
