import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export class Part2 extends Component {
  render() {
    return (
      <Fragment>

<div class="growth-service style-2 sec-padd bg1">
<Container fluid={true}>
  <Row>
  <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
    <h5>“If asked which profession is the most important, most of the people would answer immediately – the profession of a doctor.<br/> The words: “I am a doctor” inspire respect and excite admiration in any society.”</h5>
    </Col>
  </Row>
</Container>

             </div>

      </Fragment>
    )
  }
}

export default Part2
