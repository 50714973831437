import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Study_in_Russia from '../assets/images/Study-in-Russia.png';
import Why_to_study_in_Russia from '../assets/images/Why-to-study-in-Russia.jpg';
import { Helmet } from "react-helmet";

 class About_Russia extends Component {
  render() {
    return (
     <Fragment>
          <Helmet>
          <title>Medical Education in Russia,A bout Russia, Join Doctors-IEA</title>
<meta name="description" content="International students interesting for study MBBS abroad, can join Doctors-IEA for study in Russia and can save your time by getting best services. Calll +79515174507"/>
<meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
<link rel="canonical" href="https://studymedicalmbbsinrussia.co.in/about-russia/" />
<meta property="og:locale" content="en_US" />
<meta property="og:type" content="article" />
<meta property="og:title" content="Medical Education in Russia,A bout Russia, Join Doctors-IEA" />
<meta property="og:description" content="International students interesting for study MBBS abroad, can join Doctors-IEA for study in Russia and can save your time by getting best services. Calll +79515174507" />
<meta property="og:url" content="https://studymedicalmbbsinrussia.co.in/about-russia/" />
<meta property="og:site_name" content="MBBS in Russia, study MBBS in Russia, MBBS in Russia for Indian students, medical universities in Russia approved by MCI" />
<meta property="og:image" content="https://studymedicalmbbsinrussia.co.in/wp-content/uploads/2019/02/Study-in-Russia-300x212.png" />
<meta property="og:image:secure_url" content="https://studymedicalmbbsinrussia.co.in/wp-content/uploads/2019/02/Study-in-Russia-300x212.png" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:description" content="International students interesting for study MBBS abroad, can join Doctors-IEA for study in Russia and can save your time by getting best services. Calll +79515174507" />
<meta name="twitter:title" content="Medical Education in Russia,A bout Russia, Join Doctors-IEA" />
      </Helmet> 
         <div className="inner-banner has-base-color-overlay text-center bg1">
    <Container>
        <div className="box">
            <h3>About Russia & Medical Studies</h3>
        </div>
    </Container>
    <div className="breadcumb-wrapper">
        <Container>
            <div className="pull-left">
                <ul className="list-inline link-list">
                    <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                    <li> About Russia & Medical Studies</li>
                </ul>
            </div>
           
        </Container>
    </div>
</div>
<section className="about-faq sec-padd">
<Container>
    <Row>
    <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>

    <h1>About Russia & Medical Studies</h1>
    <h2>Know Medical education in Russia-A best destination for <a href="https://thedoctorsiea.in/">study MBBS in Russia</a></h2>
<p>Russia spans from the Eastern plains of Europe to the Orientals of Asia. The country covers 17,075,400 square kilometers, making it the largest country in the world. The official language is Russian, although there are some 100 languages spoken among the 160 ethnic groups registered to be living within Russian borders. This makes Russia an incredibly diverse country, with no one culture to guide your expectations. </p>
<p>The official state language is Russian. Popular foreign languages that are often included in the secondary school curriculum and widely studied include English, French, German, Italian, and Spanish. The Russian Federation or Russia is a federation and semi-presidential republic, wherein the President is the head of state. The President of Russia is Vladimir V. Putin. </p>
<p>The Russian capital and the largest city is Moscow (population more than 11 million people). There are more than 1050 cities in the country; in the European part of Russia, the largest cities are Moscow, Saint-Petersburg, Nizhny Novgorod, Veliky Novgorod, Saratov, Tambov, Astrakhan, Rostov-on-Don, and Stavropol.</p>
<p>Russia is unique in terms of confessional diversity. You will find followers of many different world religions in the country. The most popular are Christianity (Orthodoxy and Catholicism), Islam (Sunnism), Buddhism, and Judaism. The average temperature in summer is +25C. It becomes colder in autumn – from +10 C to +15 C. In winter the temperature is from – 15 C to +5 C. It becomes warmer in spring – from +10C to +15C.</p>
<p>Russian higher education is most famous for its superiority within the areas of science and research. It is said that over one-quarter of the world’s scientific literature is published in Russian – a surprisingly uniform language despite its enormous spread across country and peoples. It is also one of the six official languages of the United Nations. </p>
<p>Medical Education in Russia: Russia has become an increasingly popular destination for medical studies (MBBS) amongst International students due to its global standards of education and affordable tuition fees. Medical education in Russia is subsidized by the Russian government, as compared to western countries the tuition fees for <a href="https://thedoctorsiea.in/">MBBS in Russia</a> medical universities are affordable. The average cost of studying MBBS in Russia starts at 5000-6000 USD per year.</p>
<h4>From the Last 20 years, foreign student numbers are increasing day by day to study medicine in Russia. </h4>

<Row>
<Col  key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
<img  className="d-block w-100" src={Study_in_Russia}  alt="First slide" />
</Col>
<Col  key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
<img  className="d-block w-100" src={Why_to_study_in_Russia}  alt="First slide" />
</Col>

</Row>

    </Col>
    </Row>
</Container>
</section>

<div class="growth-service style-2 sec-padd bg1">
<Container fluid={true}>
  <Row>
  <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
<h3>To know more about Russia, university, fees, admission process contact us at  +79515174507</h3>
<h6>MBBS in Russia | Study MBBS in Rostov state medical university Russia | Study Medicine in Russia |<br/> Medical study in Russia | Kazan State Medical University | Study MBBS in Russia | MBBS in Russia for Indians</h6>
    </Col>
  </Row>
</Container>
</div>
     </Fragment>
    )
  }
}

export default About_Russia
