import React, { Component, Fragment } from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { Link } from 'react-router-dom';

import Home from '../pages/Home';
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";

import About_us from '../pages/About_us';
import About_Russia from '../pages/About_Russia';
import Admission_Process from '../pages/Admission_Process';
import Russian_language_course from '../pages/Russian_language_course';
import Faq_for_mbbs_in_russia_by_indians from '../pages/Faq_for_mbbs_in_russia_by_indians';
import Contact_us from '../pages/Contact_us';


const appHistory = {
  basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);
class AppRoute extends Component {
  render() {
    return (

      <Fragment>    
     <Header />
     <Router basename={process.env.PUBLIC_URL} history={history}>  

      <Route exact path='/' component={ Home } /> 
      <Route exact path='/about-us' component={ About_us } /> 
      <Route exact path='/about-russia' component={ About_Russia } /> 
      <Route exact path='/admission-process' component={ Admission_Process } />
      <Route exact path='/russian-language-course' component={ Russian_language_course } />
      <Route exact path='/faq-for-mbbs-in-russia-by-indians' component={ Faq_for_mbbs_in_russia_by_indians } />
      <Route exact path='/top-mbbs-in-abroad-consultants-in-delhi' component={ Contact_us } /> 
     

    </Router>  
    <Footer />
 </Fragment>

    )
  }
}

export default AppRoute;
