import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet";


 class Faq_for_mbbs_in_russia_by_indians extends Component {
  render() {
    return (
     <Fragment>
          <Helmet>
          <title>Learn Russian Language | Preparatory Course | Call Doctors-IEA</title>
<meta name="description" content="Learrn Russian Language in Russia: Rostov State Medical University, Southern Federal University, Don State Technical University, Rostov State University of Economics, Kazan Federal University, Kazan State Medical University, Udmurt State University, Peoples&#039; Federal University"/>
<meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
<link rel="canonical" href="https://studymedicalmbbsinrussia.co.in/russian-language-course/" />
<meta property="og:locale" content="en_US" />
<meta property="og:type" content="article" />
<meta property="og:title" content="Learn Russian Language | Preparatory Course | Call Doctors-IEA" />
<meta property="og:description" content="Learrn Russian Language in Russia: Rostov State Medical University, Southern Federal University, Don State Technical University, Rostov State University of Economics, Kazan Federal University, Kazan State Medical University, Udmurt State University, Peoples&#039; Federal University" />
<meta property="og:url" content="https://studymedicalmbbsinrussia.co.in/russian-language-course/" />
<meta property="og:site_name" content="MBBS in Russia, study MBBS in Russia, MBBS in Russia for Indian students, medical universities in Russia approved by MCI" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:description" content="Learrn Russian Language in Russia: Rostov State Medical University, Southern Federal University, Don State Technical University, Rostov State University of Economics, Kazan Federal University, Kazan State Medical University, Udmurt State University, Peoples&#039; Federal University" />
<meta name="twitter:title" content="Learn Russian Language | Preparatory Course | Call Doctors-IEA" />
      </Helmet>  
         <div className="inner-banner has-base-color-overlay text-center bg1">
    <Container>
        <div className="box">
            <h3>FAQ – General | MBBS in Russia for Indian students</h3>
        </div>
    </Container>
    <div className="breadcumb-wrapper">
        <Container>
            <div className="pull-left">
                <ul className="list-inline link-list">
                    <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                    <li> FAQ – General | MBBS in Russia for Indian students</li>
                </ul>
            </div>
           
        </Container>
    </div>
</div>
<section className="about-faq sec-padd">
<Container>
    <Row>
    <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>

    <h1>FAQ – General | MBBS in Russia for Indian students</h1>
   <h2> General Questions: Study in Russia | MBBS in Russia</h2>
<p>Is it safe to study in Russia? The degree is recognized or not, safe to stay in Russia. Here are the answers to all questions or doubts to get admission to Russian universities for MBBS/MS/MS etc. The below listed question is a general question. For more questions please check other categories.</p>



    </Col>
    </Row>
</Container>
</section>

<div class="growth-service style-2 sec-padd bg1">
<Container fluid={true}>
  <Row>
  <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
<h3>To know more about fees, university, admission process contact us at +91-8010503035, +79515174507</h3>
<h6>MBBS in Rostov state medical university, Russia | Study MBBS in Kazan state medical university Russia | Study medicine in Russia | Medical study in Russia | Kursk State Medical University |<br/> MBBS in Russia | MBBS in Russia for Indians | Kazan Federal University | Crimea Federal University</h6>
    </Col>
  </Row>
</Container>
</div>
     </Fragment>
    )
  }
}

export default Faq_for_mbbs_in_russia_by_indians
