import React, { Component, Fragment } from 'react'
import {Container,Row,Col} from 'react-bootstrap'

class News extends Component {
    render() {

        return (       
         <Fragment>   
            <Container>
                <Row>
                <Col lg={10} md={10} sm={10}> 
                <marquee direction="left" speed={1000}><span className="text-white"><span className="blink-text">Breaking News:</span> Dear students and parents: <span className="blink-text">Doctors-IEA</span> is proud to announce that we are the <span className="blink-text">Direct admission partners</span> of the Top 15 <span className="blink-text">Russian Medical Universities</span>. Book your seat now at <span className="blink-text">1800-419-5827</span>.
</span></marquee> 
                    </Col>
                    <Col lg={2} md={2} sm={2}> 
                    <div id="google_translate_element">  </div>
                    </Col>
                </Row>
            </Container>
            </Fragment>	
     )
    }
}
export default News;