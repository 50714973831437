import React, { Component, Fragment } from 'react'
import {Container,Row,Col,Card, Carousel} from 'react-bootstrap';
import BgSlider from '../../assets/images/Be-Doctor-With-Doctors-IEA.webp';
import BgSlider1 from '../../assets/images/Study-MBBS-in-Russia-With-Doctors-IEA.webp';



class HomeSlider extends Component {
     render() {
     

    return (
     <Fragment>
 <section className="rev_slider_wrapper">
 <Carousel variant="dark" className='bgSlider'> 
  <Carousel.Item className="tp-caption tp-resizeme">  
  <div className='overlay'></div>
    <img  className="d-block w-100" src={BgSlider}  alt="First slide" />
   
    <Carousel.Caption className="slide-content-box">
  
                    
                        <h1>A Gateway to MBBS in Russia</h1>
                        <p>Welcome to Doctors-IEA,  A leading educational consultants for study MBBS in Russia. Doctors-IEA, a one stop solution for Medical studies in Russia. We are well-equipped with all essential information and years of experience in placing Indian students in NMC recognized medical colleges in Russia.</p>
                       
                <div className="tp-caption tp-resizeme">
                    <div className="slide-content-box">
                        <div className="button">
                            <a className="thm-btn yellow-bg" href="https://www.thedoctorsiea.in/apply-online/" target="_blank">Register today with us</a>     
                        </div>
                    </div>
                </div>
    </Carousel.Caption>
  </Carousel.Item>  

  <Carousel.Item className="tp-caption tp-resizeme">  
  <div className='overlay'></div>
    <img  className="d-block w-100" src={BgSlider1}  alt="First slide" />
   
    <Carousel.Caption className="slide-content-box">
  
                    
                        <h1>Study MBBS in Russia</h1>
                        <p>Course duration of  MBBS in Russia is 6 years. Study MBBS in Russia at a low cost, the yearly tuition fee in medical colleges is around 2.5-5 lakhs only. More than 40+ universities are approved and recognized by NMC and WHO. For more info call Doctors-IEA expert team at 8010503035.</p>
                       
                <div className="tp-caption tp-resizeme">
                    <div className="slide-content-box">
                        <div className="button">
                            <a className="thm-btn yellow-bg" href="https://studymedicalmbbsinrussia.co.in/mbbs-in-russia/" target="_blank">Book your seat now</a>     
                        </div>
                    </div>
                </div>
    </Carousel.Caption>
  </Carousel.Item> 
 </Carousel>

 </section>   </Fragment>
    )
  }
}

export default HomeSlider
