import React, { Component, Fragment } from 'react'
import {Container, Row, Col,Button} from 'react-bootstrap';

export class TopHeader extends Component {
  render() {
    return (
      <Fragment>
         <Col lg={12} md={12} sm={12}> 
         <div className='top-bar'>
                <ul className="top-bar-text">
                    <li><i className="fa fa-map"></i>608,6thFloor,ACP-1, NSP-New Delhi (India)</li>
                    <li><i className="fa fa-clock"></i>Mon - Satday: 9.00 am to 6.00 pm.</li>
                </ul>
                <ul className="social">
                    <li><a href="https://www.facebook.com/DoctorsIEATeamOfficialpage/" target="_blank"><i className="fab fa-facebook"></i></a></li>
                    <li><a href="https://twitter.com/doctors_ieateam" target="_blank"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/doctors-iea-study-medical-mbbs-in-russia-71923150/" target="_blank"><i className="fab fa-linkedin"></i></a></li> 
                    <li><a href="https://www.instagram.com/accounts/login/?next=/doctors.iea_officials/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                    <li><a href="https://www.youtube.com/channel/UCj8PuH9COYussD341o6daRw" target="_blank"><i className="fab fa-youtube"></i></a></li>            
                </ul>
                </div>
            </Col>
      </Fragment>
    )
  }
}

export default TopHeader
