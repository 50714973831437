import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ContactSlider from '../components/home/ContactSlider'
import { Helmet } from "react-helmet";

export class Contact_us extends Component {
  render() {
    return (
      <Fragment>
         <Helmet>
         <title>MBBS Abroad Consultants in Delhi, MBBS in Russia Top Consultants Delhi</title>
<meta name="description" content="Get Direct MBBS Admission in Russia with Top Educational Consultants for MBBS Abroad in Delhi. Official Representatives of Top Medical Colleges of Russia. MBBS in Russia Top Consultants in Delhi, Mumbai, Banglore, UttarPradesh, Bihar, Gujarat, Haryana, Hyderabad, Calcutta, Chennai, Kerala."/>
<meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
<link rel="canonical" href="https://studymedicalmbbsinrussia.co.in/top-mbbs-in-abroad-consultants-in-delhi/" />
<meta property="og:locale" content="en_US" />
<meta property="og:type" content="article" />
<meta property="og:title" content="MBBS Abroad Consultants in Delhi, MBBS in Russia Top Consultants Delhi" />
<meta property="og:description" content="Get Direct MBBS Admission in Russia with Top Educational Consultants for MBBS Abroad in Delhi. Official Representatives of Top Medical Colleges of Russia. MBBS in Russia Top Consultants in Delhi, Mumbai, Banglore, UttarPradesh, Bihar, Gujarat, Haryana, Hyderabad, Calcutta, Chennai, Kerala." />
<meta property="og:url" content="https://studymedicalmbbsinrussia.co.in/top-mbbs-in-abroad-consultants-in-delhi/" />
<meta property="og:site_name" content="MBBS in Russia, study MBBS in Russia, MBBS in Russia for Indian students, medical universities in Russia approved by MCI" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:description" content="Get Direct MBBS Admission in Russia with Top Educational Consultants for MBBS Abroad in Delhi. Official Representatives of Top Medical Colleges of Russia. MBBS in Russia Top Consultants in Delhi, Mumbai, Banglore, UttarPradesh, Bihar, Gujarat, Haryana, Hyderabad, Calcutta, Chennai, Kerala." />
<meta name="twitter:title" content="MBBS Abroad Consultants in Delhi, MBBS in Russia Top Consultants Delhi" />
      </Helmet> 
      <div className="inner-banner has-base-color-overlay text-center bg1">
    <Container>
        <div className="box">
            <h3>Contact Us</h3>
        </div>
    </Container>
    <div className="breadcumb-wrapper">
        <Container>
            <div className="pull-left">
                <ul className="list-inline link-list">
                    <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                    <li> Contact Us</li>
                </ul>
            </div>
           
        </Container>
    </div>
</div>
<section className="about-faq sec-padd">
<Container>
    <Row>
    <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
    <h1>Choose the best for you | Get Direct MBBS Admission in Russia with Official Representative of Top universities</h1>
   </Col>
    </Row>
    <Row>
    <Col  key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
    <h2>Doctors-IEA Pvt. Ltd</h2>

    <ul>
  <li><strong>Landline:</strong> 011-27358827</li>
  <li><strong>Mobile:</strong> +91-8010503035, +91-7428212236</li>
  <li><strong>Toll-Free:</strong> <strong>1800-419-5827</strong></li>
  <li><strong>Russia:</strong> +79515174507</li>
  <li><strong>For Students:</strong> thedoctors.iea@gmail.com, thedoctors.iea@mail.ru</li>
  <li><strong>For Agents:</strong> contact@thedoctorsiea.in</li>
  <li><strong>Official Wesbites:</strong> www.thedoctorsiea.in, www.studymedicalmbbsinrussia.co.in</li>
</ul>
    
    </Col>

   <Col  key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
   <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13996.981778157327!2d77.15903!3d28.71221!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x18541e282325b6d7!2sMBBS%20in%20Russia%20%7C%20Study%20MBBS%20Abroad%20Russia%20%7C%20Doctors-IEA%20(Top%20Consultants%20for%20Russia)!5e0!3m2!1sen!2sin!4v1650048038682!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>  </Col>

    </Row>



    <Row>
    <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
    <p>Doctors-IEA is one of India’s reputed and leading consultants for MBBS in Russia. We have our representative in all the prominent and NMC recognized medical universities in Russia. You can consult us to get the complete list of NMC recognized Russian medical colleges and universities that have a medium of instruction in English as well as Russian and bilingual medium. During the consultation, we provide precise accurate information on both costs of education and living to study MBBS in Russia so that you can compare with the offering from other agents and eventually take the right decision in the end.</p>
    </Col>
    </Row>
</Container>
</section>

<div class="growth-service style-2 sec-padd bg1">
<Container fluid={true}>
  <Row>
  <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
<h3>''Be aware of Unauthorized Agents. Get Direct Admission from Registered & Authorized Representative Only''</h3>
<h6>Welcome to the world of Doctors</h6>
<h6>''Be Doctor with Doctors'' ... Doctors-IEA Team</h6>
    </Col>
  </Row>
</Container>
</div>
     </Fragment>
    )
  }
}

export default Contact_us
