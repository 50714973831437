import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet";

 class Admission_Process extends Component {
  render() {
    return (
     <Fragment>
        <Helmet>
        <title>Admission Procedure, Get Direct MBBS Admission in Russian Medical Colleges</title>
<meta name="description" content="Admission Process: Get Direct MBBS Admission in Russian Medical universities. Join Doctors-IEA for A-grade services. Call 8010503035 www.thedoctorsiea.in"/>
<meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
<link rel="canonical" href="https://studymedicalmbbsinrussia.co.in/admission-process/" />
<meta property="og:locale" content="en_US" />
<meta property="og:type" content="article" />
<meta property="og:title" content="Admission Procedure, Get Direct MBBS Admission in Russian Medical Colleges" />
<meta property="og:description" content="Admission Process: Get Direct MBBS Admission in Russian Medical universities. Join Doctors-IEA for A-grade services. Call 8010503035 www.thedoctorsiea.in" />
<meta property="og:url" content="https://studymedicalmbbsinrussia.co.in/admission-process/" />
<meta property="og:site_name" content="MBBS in Russia, study MBBS in Russia, MBBS in Russia for Indian students, medical universities in Russia approved by MCI" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:description" content="Admission Process: Get Direct MBBS Admission in Russian Medical universities. Join Doctors-IEA for A-grade services. Call 8010503035 www.thedoctorsiea.in" />
<meta name="twitter:title" content="Admission Procedure, Get Direct MBBS Admission in Russian Medical Colleges" />
      </Helmet> 
         <div className="inner-banner has-base-color-overlay text-center bg1">
    <Container>
        <div className="box">
            <h3>Admission Process</h3>
        </div>
    </Container>
    <div className="breadcumb-wrapper">
        <Container>
            <div className="pull-left">
                <ul className="list-inline link-list">
                    <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                    <li> Admission Process</li>
                </ul>
            </div>
           
        </Container>
    </div>
</div>
<section className="about-faq sec-padd">
<Container>
    <Row>
    <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>

    <h1>Admission Process</h1>
    <h2>Doctors-IEA provides complete guidance to students at every step. Please check the below documents list for applying for <a href="http://thedoctorsiea.in/">MBBS admission in Russia</a>:</h2>
<p>STEP 1: Completed online application through the Doctors-IEA website (including full name, date of birth, passport number, citizenship, permanent address, contact phone, and e-mail of applicant).</p>
<ul>
  <li>Copy of Passport, (Minimum duration of 2 years)</li>
  <li>Secondary high school educational certificate, transcripts.   </li>
  <li>Birth certificate (if available).</li>
  <li>Medical fitness report.</li>
  <li>HIV test results.</li>
  <li>20  photographs on matte paper (3×4 cm).</li>
  <li>The age limit for applicants to higher educational establishments is 17-25 years. For postgraduate candidates-35 years. For Doctoral Candidates-45 years.</li>
</ul>
<h3>We Provide the following services to Indian students;</h3>
<ul>
  <li>Obtaining  Provisional Letter from University.</li>
  <li>Obtaining a confirmation Admission Letter from University.</li>
  <li>Obtaining an Invitation Letter from the Ministry of Foreign Affairs of the Russian Federation.</li>
  <li>Apostille Documents (Certificates) by the Ministry of HRD &amp; External Affairs India.</li>
  <li>Obtaining Visa from the Russian Embassy.</li>
  <li>Air Ticket Delhi/Mumbai/Chennai to Moscow or particular cities.</li>
  <li>Immigration clearance for Travel to Russia.</li>
  <li>Receive the student on his/her first arrival to Russia.</li>
  <li>Admission formalities, Registration, Visa extension In Russia.</li>
  <li>Services to Indians students until their degree completion.</li>
</ul>

    </Col>
    </Row>
</Container>
</section>

<div class="growth-service style-2 sec-padd bg1">
<Container fluid={true}>
  <Row>
  <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
<h3>To know more about fees, university, admission process contact us at +91-8010503035, +79515174507</h3>
<h6>MBBS in Rostov state medical university, Russia | Study MBBS in Kazan state medical university Russia | Study medicine in Russia | Medical study in Russia |<br/> Kursk State Medical University | MBBS in Russia | MBBS in Russia for Indians | Kazan Federal University | Crimea Federal University</h6>
    </Col>
  </Row>
</Container>
</div>
     </Fragment>
    )
  }
}

export default Admission_Process
