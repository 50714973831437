import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export class Part3 extends Component {
  render() {
    return (
      <Fragment>

<div class="growth-service style-2 sec-padd bg1">
<Container fluid={true}>
  <Row>
  <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
    <h5>Medical Degree, MBBS in Russia, Medical Courses in Russia, Study in Russia, Top MBBS Colleges in Russia,<br/> Direct Admission in MBBS, Study MBBS in Russia 2022-23, MBBS in Russia for Indian Students</h5>
    </Col>
  </Row>
</Container>
</div>

      </Fragment>
    )
  }
}

export default Part3
