import React, { Component, Fragment } from 'react'
import {Container, Row, Col,Button} from 'react-bootstrap';
import MainLogo from './MainLogo';
import Menu from './Menu';
import News from  './News';
import TopHeader from './TopHeader';




 class Header extends Component {
     componentDidMount(){
          window.scroll(0,0)
      } 
     render() {
          return (
               <Fragment>
              <header className="header-area">
                   <div className="news">
              <News />
              </div>
                   <Container>  
                        <Row>                     
                        <TopHeader />
                        </Row> 
                        <Row> 
                        <MainLogo />
                        </Row>
                        <Row> 
                        <Menu />
                        </Row>
                   </Container>

                           
         </header>      
             
               </Fragment>
          )
     } 
}
export default Header