import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet";

class About_us extends Component {
  render() {
    return (
     <Fragment>
         <Helmet>
         <title>Study Medical MBBS in Russia, MBBS Direct Admissions Russia</title>
<meta name="description" content="Study Medical MBBS in Russia, MBBS Admissions for Indian Students Call for direct admission 8010503035. Kazan state medical university, rostov state medical university, kazan federal university, crimea federal university, bukovinian state medical university, kursk state medical university"/>
<meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
<link rel="canonical" href="https://studymedicalmbbsinrussia.co.in/about-us/" />
<meta property="og:locale" content="en_US" />
<meta property="og:type" content="article" />
<meta property="og:title" content="Study Medical MBBS in Russia, MBBS Direct Admissions Russia" />
<meta property="og:description" content="Study Medical MBBS in Russia, MBBS Admissions for Indian Students Call for direct admission 8010503035. Kazan state medical university, rostov state medical university, kazan federal university, crimea federal university, bukovinian state medical university, kursk state medical university" />
<meta property="og:url" content="https://studymedicalmbbsinrussia.co.in/about-us/" />
<meta property="og:site_name" content="MBBS in Russia, study MBBS in Russia, MBBS in Russia for Indian students, medical universities in Russia approved by MCI" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:description" content="Study Medical MBBS in Russia, MBBS Admissions for Indian Students Call for direct admission 8010503035. Kazan state medical university, rostov state medical university, kazan federal university, crimea federal university, bukovinian state medical university, kursk state medical university" />
<meta name="twitter:title" content="Study Medical MBBS in Russia, MBBS Direct Admissions Russia" />
      </Helmet> 
         <div className="inner-banner has-base-color-overlay text-center bg1">
    <Container>
        <div className="box">
            <h3>About Us</h3>
        </div>
    </Container>
    <div className="breadcumb-wrapper">
        <Container>
            <div className="pull-left">
                <ul className="list-inline link-list">
                    <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                    <li> About Us </li>
                </ul>
            </div>
           
        </Container>
    </div>
</div>
<section className="about-faq sec-padd">
<Container>
    <Row>
    <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>

    <h1><a href="https://thedoctorsiea.in/study-mbbs-russia/">Doctors-IEA</a> promoting only Top Ranked universities of Russia</h1>
<h2>NMC, WHO recognized Universities only  for <a href="https://thedoctorsiea.in/">study MBBS in Russia</a></h2>
<p>Russian Universities world Ranking is high. Maximum Universities of Russia are of High standard with excellent teaching staff. Before joining or finding the University of your choice please feel free &amp; without hesitation contact the <a href="https://www.thedoctorsiea.in/">DOCTORS-IEA TEAM</a>, we can help you with reliable information for admission and the current world rating &amp; country rating of the selected university. Get admission to <a href="https://studymedicalmbbsinrussia.co.in/">study MBBS in Russia</a> from an official authorized representative of top medical universities: Doctors-Ideal educational advisors. Doctors-IEA has been of great help to an Indian student who aspires to study MBBS in Russia in Top Colleges. They act as a one-stop consultant for a study MBBS abroad in Russia. Doctors-IEA has aided a lot of students in terms of choosing the best prestigious universities, courses offered, tuition fees, admission procedures, study opportunities, bi-lateral exchange programs, etc. Doctors-IEA has helped Indian students since 2007 to join the Russian universities successfully.  It is, however, advisable to seek admission through Doctors’ ideal educational advisors that are well recognized and authorized by the Russian Top Medical Universities.  <a href="https://studymedicalmbbsinrussia.co.in/">www.studymedicalmbbsinrussia.co.in</a>,<a href="http://thedoctorsiea.in/"> www.thedoctorsiea.in</a> is the website that makes it easy for international students to apply online.</p>
<p>The Doctors-IEA is one of the country’s reputed and leading consultants for <a href="https://thedoctorsiea.in/">MBBS in Russia.</a> We have our representative in all the prominent and MCI recognized medical universities in Russia. You can consult us to get the complete list of MCI recognized Russian medical colleges and universities that have a medium of instruction in English as well as a Russian medium. During the consultation, we provide precise accurate information on both costs of education and living to study <a href="https://thedoctorsiea.in/">MBBS in Russia</a> so that you can compare with the offering from other agents and eventually take the right decision in the end.</p>
<h3>Dear aspirants,</h3>
<p>Russian universities are accepting admissions on previous school records/grades and some are accepting through the entrance exam. We are pleased to announce that we have started admissions for this academic year. We accept applications for September-October intake. The students who are interested in the <a href="https://thedoctorsiea.in/">Study MBBS in Russia </a>should send us their following documents.     </p>
<ul>
  <li>Filled Application Form through our website.</li>
  <li>Copy of Passport.</li>
  <li>Copy of High school &amp; Secondary school certificate</li>
  <li>NEET eligibility certificate.</li>
</ul>


    </Col>
    </Row>
</Container>
</section>

<div class="growth-service style-2 sec-padd bg1">
<Container fluid={true}>
  <Row>
  <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
<h3>To know more about fees, university, admission process contact us at +91-8010503035, +79515174507</h3>
<h6>MBBS in Rostov state medical university, Russia | Study MBBS in Kazan state medical university Russia | Study Medicine in Russia |<br/> Medical study in Russia | Rostov State Medical University | Study MBBS in Russia | MBBS in Russia for Indians | Study in Russia </h6>
    </Col>
  </Row>
</Container>
</div>
     </Fragment>
    )
  }
}

export default About_us
