import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export class Part1 extends Component {
  render() {
    return (
     <Fragment>
          <Container className='sec-padd part1'>
               <Row>
               <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
                 <h1>Leading International Educational Consultant : Doctors-IEA For MBBS in Russia</h1>
<h2>STUDY MBBS IN RUSSIA | MCI APPROVED RUSSIAN MEDICAL UNIVERSITIES | DOCTORS-IEA SPECIALIZED CONSULTANTS FOR MBBS IN RUSSIA FOR INDIAN STUDENTS | GET LATEST FEE STRUCTURE FOR MBBS IN RUSSIA</h2>
<p>Welcome to the world of education and prospects. In today's world education is the key asset that can make one stand out of crowd, Doctors-IEA is lead & managed by experienced educational advisors. Doctors-IEA, being a credible consultant for international education advisor, we lead your ambition from the front until you achieve admission in your choice of a medical university in Russia. We are determined to bring laurels into the world of medical education abroad. We comprehensively provide precise guidance and suggest aspiring candidates the right path that eventually brings them to their dream destination of getting admission in NMC recognized medical colleges in Russia with surprisingly affordable MBBS abroad fees. We leave no stone unturned to facilitate admission in the most reputed and renowned NMC recognized and globally acknowledged medical universities in Russia.</p>
                 </Col>
               </Row>
             </Container>
     </Fragment>
    )
  }
}

export default Part1
