import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Socialmedia from './Socialmedia'


class Footer extends Component {
  render() {
    return (
     <Fragment>
       <footer class="main-footer  bg2">
         <Container>
<Row>
<Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>

<div class="section-title">
<h3>Contacts Information</h3>

                    </div>
   <ul className="contact-infos">
   <li>
                            
                            <div className="text-box">
                                <h4>Doctors-IEA Pvt. Ltd</h4>
                            </div>
                        </li>
                        <li>
                            <div className="icon_box">
                                <i className="fa fa-map"></i>
                            </div>
                            <div className="text-box">
                                <h5>608, 6thFloor<br/> Aggarwal Cyber Plaza-1<br />Netaji Subhash Pllace<br /> Pitampura (New Delhi)</h5>
                            </div>
                        </li>
                        <li>
                            <div className="icon_box">
                                <i className="fa fa-phone"></i>
                            </div>
                            <div className="text-box">
                                <h5>Call Us Now</h5>
                                <p><a href="tel:1800-419-5827" target="_blank">1800-419-5827</a></p>
                                <p><a href="tel:+91-8010503035" target="_blank">+91-8010503035</a></p>
                            </div>
                        </li>
                        <li>
                            <div className="icon_box">
                                <i className="fa fa-envelope"></i>
                            </div>
                            <div className="text-box">
                                <h5>Send Mail Us</h5>
                                <p><a href="mailto:contact@thedoctorsiea.in" target="_blank">contact@thedoctorsiea.in</a></p>
                            </div>
                        </li>
                    </ul>
         
  </Col>
  <Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>

  <div className="footer-link-widget">
                    <div className="section-title">
                        <h3>Top 10 Medical Universities</h3>
                    </div>
                 <ul className="list">
<li><a href="https://studymedicalmbbsinrussia.co.in/kazan-state-medical-university/" target="_blank">Kazan State Medical University</a></li>
<li><a href="https://thedoctorsiea.in/rostov-state-medical-university/" target="_blank">Rostov State Medical University</a></li>
<li><a href="https://studymedicalmbbsinrussia.co.in/kazan-federal-university/" target="_blank">Kazan Federal University -IFMB</a></li>
<li><a href="https://studymedicalmbbsinrussia.co.in/crimea-federal-university/" target="_blank">Crimea Federal University</a></li>
<li><a href="https://studymedicalmbbsinrussia.co.in/kursk-state-medical-university/" target="_blank">Kursk State Medical University</a></li>
<li><a href="https://studymedicalmbbsinrussia.co.in/north-ossetian-state-medical-academy/" target="_blank">North-Ossetian Medical Academy</a></li>
<li><a href="https://studymedicalmbbsinrussia.co.in/stavropol-state-medical-university/" target="_blank">Stavropol State Medical University</a></li>
<li><a href="https://studymedicalmbbsinrussia.co.in/peoples-friendship-university/" target="_blank">People&#8217;s Friendship University</a></li>
<li><a href="https://studymedicalmbbsinrussia.co.in/rostov-state-medical-university/" target="_blank">Volgograd State Medical University</a></li>
                            </ul>
                        </div>

    </Col>
    <Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>

    <div className="footer-link-widget">
                    <div className="section-title">
                        <h3>Top 5 Technical Universities</h3>
                    </div>
                 <ul className="list">
<li><a href="https://www.thedoctorsiea.in/top-technical-universities-russia/" target="_blank">Don State Technical University</a></li>
<li><a href="https://www.thedoctorsiea.in/top-technical-universities-russia/" target="_blank">Southern Federal University</a></li>
<li><a href="https://www.thedoctorsiea.in/top-technical-universities-russia/" target="_blank">Ukhta State Technical University</a></li>
<li><a href="https://www.thedoctorsiea.in/top-technical-universities-russia/" target="_blank">St.Petersburg Polytechnic University</a></li>
<li><a href="https://www.thedoctorsiea.in/top-technical-universities-russia/" target="_blank">Udmurt State University</a></li>
<li><a href="https://studymedicalmbbsinrussia.co.in/russian-language-course/" target="_blank">Russian Language Course</a></li>
<li><a href="https://studymedicalmbbsinrussia.co.in/mbbs-fees-in-russia/" target="_blank">Fee Structure </a></li>
<li><a href="https://www.thedoctorsiea.in/" target="_blank">Portal for International Students</a></li>                            
</ul>
</div>
                       

      </Col>

</Row>

         </Container>

       </footer>
        <Container className='text-center footer' fluid={true}>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
          <p>Copyright © 2022. All rights reserved. Study MBBS in Russia for Indian Students Presented by Doctors-IEA Team</p>
          </Col>
          </Row>
          </Container>

    <Socialmedia />
     </Fragment> 



    )
  }
}

export default Footer
