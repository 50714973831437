import React, { Component, Fragment } from 'react'
import Services from '../components/home/Services';
import HomeSlider from '../components/home/HomeSlider';
import Features from '../components/home/Features';
import Faq from '../components/layout/Faq';
import { Helmet } from "react-helmet";
import Part1 from '../components/home/Part1';
import Part2 from '../components/home/Part2';
import Part3 from '../components/home/Part3';





export class Home extends Component {
  render() {

    return (
      <Fragment>
        <Helmet>
          <title>Study MBBS in Russia For Indian Students 2023 - 2024 | benefits of MBBS in Russia</title>
          <meta name="description" content="Study MBBS in Rusia in english. MCI approved Russian Medical Universities, benefits of MBBS in Russia, duration, Direct admission" />
          <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
          <link rel="canonical" href="https://studymedicalmbbsinrussia.co.in/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Study MBBS in Russia - MCI approved Russian Medical Universities, benefits of MBBS in Russia, duration," />
          <meta property="og:description" content="Study MBBS in Rusia in english. MCI approved Russian Medical Universities, benefits of MBBS in Russia, duration, Direct admission" />
          <meta property="og:url" content="https://studymedicalmbbsinrussia.co.in/" />
          <meta property="og:site_name" content="MBBS in Russia, study MBBS in Russia, MBBS in Russia for Indian students, medical universities in Russia approved by MCI" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description" content="Study MBBS in Rusia in english. MCI approved Russian Medical Universities, benefits of MBBS in Russia, duration, Direct admission" />
          <meta name="twitter:title" content="Study MBBS in Russia - MCI approved Russian Medical Universities, benefits of MBBS in Russia, duration," />
        </Helmet>
        <HomeSlider />
        <Features />
        <Services />
        <Part1 />
        <Part2 />
        <Faq />
        <Part3 />
      </Fragment>
    )
  }
}

export default Home;
