import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap'


export class Faq extends Component {
    render() {
        return (
           <Fragment>
             
         <Container className='text-center faq' fluid={true}>
        
            

         <div className='section-title text-center'>
                <h2>Frequently Asked Questions</h2>
             
              </div>
         <Container>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
         
         
          <Accordion defaultActiveKey="0">
  <Accordion.Item eventKey="0">
    <Accordion.Header><h4>Why MBBS in Russia with Doctors-IEA? | Become a Doctor with Doctors | Study MBBS in Russia with Doctors-IEA the Official Authorized Representative.</h4></Accordion.Header>
    <Accordion.Body>
    <h3>Reasons which make Doctors-IEA Most trusted educational consultants for <a href="https://thedoctorsiea.in/">MBBS in Russia</a>.</h3>
<p>Doctors-IEA has the full experience to provide professional consulting services for students who plan to study <a href="https://thedoctorsiea.in/">MBBS in Russia</a>. Doctors-IEA has close contact with Russian Medical Universities for the course information, application requirements, and procedures. Our team is fully ready to handle student applications effectively and efficiently. Doctors-IEA sincerely cares for our student’s future and is committed to providing reliable information and keeping to our promise.</p>
<ul>
  <li>Doctors-IEA Team is well recognized for its reliability. For its professional service and teams of experienced staff in the representative offices, Doctors-IEA Team is highly regarded within the industry by University, parents, and students alike.</li>
  <li>Doctors-IEA never charges any hidden expenses.</li>
  <li>Doctors-IEA has an excellent student-parent support system.</li>
  <li>Doctors-IEA assures financial &amp; educational assistance without any hurdle.</li>
  <li>Doctors-IEA Team will assist in the whole process of admission including your visa and travel.</li>
</ul>
  
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header><h4>Benefits of MBBS in Russia | Study MBBS Abroad in Russia | Choose the Selective & Top Medical Universities in Russia with Doctors-IEA.</h4></Accordion.Header>
    <Accordion.Body>
  
    <ul>
      <li>The duration of <a href="https://thedoctorsiea.in/"><strong>MBBS in Russia</strong></a> is 6 years.</li>
      <li>No complicated admission process for international students. It offers direct admission to study <a href="https://thedoctorsiea.in/">MBBS in Russia</a> for Indian students.</li>
      <li>Students are offered 100% English medium <strong><a href="https://thedoctorsiea.in/">study in MBBS in Russia</a></strong>.</li>
      <li>There is no entrance test or test of English language proficiency such as IELTS, TOEFL, etc.</li>
      <li>Medical education in Russia is of huge value.</li>
      <li>The degree of Russian Medical Universities is recognized around the world.</li>
      <li>Students from India come in huge numbers to pursue the <a href="https://thedoctorsiea.in/"><strong>MBBS course in Russia.</strong></a></li>
      <li>Indian food and hostel facility is available for Indian students.</li>
      <li>Medical Universities in Russia are duly recognized and approved by NMC &amp; WHO.</li>
      <li>Modern technology and techniques are utilized for teaching practicals during medical education.</li>
      <li>Universities have their own hostels that are well-equipped with daily facilities.</li>
    </ul>
 
<h2>Admission Helpline No. 1800-419-5827</h2>
  </Accordion.Body>
  </Accordion.Item>


<Accordion.Item eventKey="2">
    <Accordion.Header><h4>It is needless to say that study MBBS in Russia is one of the aspiring dreams for Indian students. One of the reasons why pursuing MBBS in Russia for Indian students happens to be a great option is the affordable MBBS abroad fees structure and direct admission procedure in top medical universities despite having world-class and high-quality medical education.</h4></Accordion.Header>
    <Accordion.Body>
   
    <p>Some of the top-notch universities in these countries where you can apply for direct admission to <a href="https://thedoctorsiea.in/"><strong>study MBBS in Russia </strong></a>at low cost include:</p>
<ul>
  <li><a href="https://thedoctorsiea.in/rostov-state-medical-university/">Rostov State Medical University</a></li>
  <li><a href="https://thedoctorsiea.in/kazan-state-medical-university/">Kazan State Medical University</a></li>
  <li><a href="https://thedoctorsiea.in/kursk-state-medical-university-russia/">Kursk State Medical University</a></li>
  <li><a href="https://thedoctorsiea.in/kazan-federal-university/">Kazan Federal University</a> (IFMB)</li>
  <li><a href="https://rrecrussia.com/crimea-federal-university/">Crimea Federal University</a></li>
  <li>People’s Friendship University</li>
  <li>North-Ossetian State Medical Academy</li>
  <li>Volgograd State Medical University</li>
  <li>Stavropol State Medical University</li>
</ul>

     </Accordion.Body>
  </Accordion.Item>



</Accordion>
          </Col>
          </Row>
          </Container>
          </Container>
           </Fragment>
            )
        }
    }
    export default Faq;