import React, { Component, Fragment } from 'react'
import {Container,Row,Col} from 'react-bootstrap'
import Logo from '../../assets/images/logo.webp';
import {Link} from "react-router-dom";

class MainLogo extends Component {
  render() {
    return (
      <Fragment>
          <div className="top-logo">
              <Row>
 <Col lg={4} md={4} sm={12}> 
                <div className="main-logo text-center">
                   <a href={process.env.PUBLIC_URL + '/'}> <img classNameName="nav-logo" src={Logo} /> </a>
                </div>
                </Col>
                <Col lg={8} md={8} sm={12}> 

                <div className="top-info">
                    <ul>
                        <li className="single-info-box">
                            <div className="icon-holder">
                                <span className="fa fa-phone"></span>
                            </div>
                            <div className="text-holder">
                                <p><span>Call Us Now</span><br /><a href="tel:1800-419-5827" target="_blank">1800-419-5827</a></p>
                            </div>
                        </li>
                        <li className="single-info-box">
                            <div className="icon-holder">
                                <span className="fa fa-envelope"></span>
                            </div>
                            <div className="text-holder">
                                <p><span>Send Mail Us</span> <br /><a href="mailto:contact@thedoctorsiea.in" target="_blank">contact@thedoctorsiea.in</a></p>
                            </div>
                        </li>
                       
                    </ul>    
                </div> 
                </Col>
                </Row>
                </div>
      </Fragment>
    )
  }
}

export default MainLogo
