import React, { Component, Fragment } from 'react'
import { Col, Container, Figure, Row } from 'react-bootstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import s1 from '../../assets/images/s1.jpg';
import s2 from '../../assets/images/s2.jpg';
import s3 from '../../assets/images/s3.jpg';
import s4 from '../../assets/images/s4.jpg';
import s5 from '../../assets/images/s5.jpg';
import s6 from '../../assets/images/s6.jpg';
import s7 from '../../assets/images/s7.jpg';
import s8 from '../../assets/images/s8.jpg';


export class Services extends Component {
    constructor(props){
        super(props);
        this.next=this.next.bind(this);
        this.previous=this.previous.bind(this)
    }
    next(){
        this.slider.slickNext();
    }
    previous(){
        this.slider.slickPrev();
    }
  render() {

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed:3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
      <Fragment>
        <section className="features services">
        <Container>
        <div className='section-title text-center'>
                <h2>Preferred Universities by Indian Students</h2>
             
              </div>
          <Row>
          <Slider ref={c=>(this.slider=c)} {...settings}>
          <Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="item">
           <Figure className="img-box">

           <a href="#"><img src={s1} alt="" className='img-fluid' /></a>        
           <div className="bottom-content">
            <h4>Rostov State Medical University</h4>
            </div> 
            <div className="overlay-box">
                            <div className="inner-box">
                            <h4>Rostov State Medical University</h4>
                                <div className="text">
                             <p>RREC Pvt. Ltd (Reliable Russian Educational Consultants) is the authorized official representative of Indians.</p>
                                </div>
                                <div className="link">
                                    <a href="https://rrecrostov.com/" target="_blank" className="default_link">READ MORE <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                                
                        </div>

            </Figure>  
         </div>
          </Col>

          <Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="item">
           <Figure className="img-box">

           <a href="#"><img src={s2} className='img-fluid' alt="" /></a>        
           <div className="bottom-content">
            <h4>Kazan state medical university</h4>
            </div> 
            <div className="overlay-box">
                            <div className="inner-box">
                            <h4>Kazan state medical university</h4>
                                <div className="text">
                             <p>As per the trend in the last decade or two, Russian Universities have emerged as the top destination for MBBS aspirants.</p>
                                </div>
                                <div className="link">
                                    <a href="https://thedoctorsiea.in/kazan-state-medical-university/" target="_blank" className="default_link">READ MORE <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                                
                        </div>

            </Figure>  
         </div>
          </Col>
          
           <Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="item">
           <Figure className="img-box">

           <a href="#"><img src={s3} className='img-fluid' alt="" /></a>        
           <div className="bottom-content">
            <h4>Kazan Federal University</h4>
            </div> 
            <div className="overlay-box">
                            <div className="inner-box">
                            <h4>Kazan Federal University</h4>
                                <div className="text">
                             <p>Kazan Federal University (Institute of Fundamental Medicine and Biology) is an integral department of Kazan federal university</p>
                                </div>
                                <div className="link">
                                    <a href="https://thedoctorsiea.in/kazan-federal-university/" target="_blank" className="default_link">READ MORE <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                                
                        </div>

            </Figure>  
         </div>
          </Col>

          <Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="item">
           <Figure className="img-box">

           <a href="#"><img src={s4} className='img-fluid' alt="" /></a>        
           <div className="bottom-content">
            <h4>Kursk State Medical University</h4>
            </div> 
            <div className="overlay-box">
                            <div className="inner-box">
                            <h4>Kursk State Medical University</h4>
                                <div className="text">
                             <p>Many medical aspirants, who want to accomplish their undergraduate medical education abroad.</p>
                                </div>
                                <div className="link">
                                    <a href="https://thedoctorsiea.in/kursk-state-medical-university-russia/" target="_blank" className="default_link">READ MORE <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                                
                        </div>

            </Figure>  
         </div>
          </Col>
          
          
           <Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="item">
           <Figure className="img-box">

           <a href="#"><img src={s5} className='img-fluid' alt="" /></a>        
           <div className="bottom-content">
            <h4>Crimea Federal University</h4>
            </div> 
            <div className="overlay-box">
                            <div className="inner-box">
                            <h4>Crimea Federal University</h4>
                                <div className="text">
                             <p>If you really want to study MBBS in Russia at one of the most regarded and top medical universities in Russia,</p>
                                </div>
                                <div className="link">
                                    <a href="https://rrecrussia.com/crimea-federal-university/" target="_blank" className="default_link">READ MORE <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                                
                        </div>

            </Figure>  
         </div>
          </Col>
          
          
           <Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="item">
           <Figure className="img-box">

           <a href="#"><img src={s6} className='img-fluid' alt="" /></a>        
           <div className="bottom-content">
            <h4>Peoples' Friendship University</h4>
            </div> 
            <div className="overlay-box">
                            <div className="inner-box">
                            <h4>Peoples' Friendship University</h4>
                                <div className="text">
                             <p>People's Friendship University is formed in 1960 and the Institute for Medicine is integrated in the same year.</p>
                                </div>
                                <div className="link">
                                    <a href="https://thedoctorsiea.in/peoples-friendship-university-russia/" target="_blank" className="default_link">READ MORE <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                                
                        </div>

            </Figure>  
         </div>
          </Col>
          
          
           <Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="item">
           <Figure className="img-box">

           <a href="#"><img src={s7} className='img-fluid' alt="" /></a>        
           <div className="bottom-content">
            <h4>North Ossetian State Medical Academy</h4>
            </div> 
            <div className="overlay-box">
                            <div className="inner-box">
                            <h4>North Ossetian State Medical Academy</h4>
                                <div className="text">
                             <p>North – Ossetian State Medical Academy is located in the town Vladikavkaz one of the most beautiful towns of the Large Caucasian Mountains.</p>
                                </div>
                                <div className="link">
                                    <a href="https://thedoctorsiea.in/north-ossetian-state-medical-academy-russia/" target="_blank" className="default_link">READ MORE <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                                
                        </div>

            </Figure>  
         </div>
          </Col>
          
          
           <Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="item">
           <Figure className="img-box">

           <a href="#"><img src={s8} className='img-fluid' alt="" /></a>        
           <div className="bottom-content">
            <h4>Stavropol State Medical University</h4>
            </div> 
            <div className="overlay-box">
                            <div className="inner-box">
                            <h4>Stavropol State Medical University</h4>
                                <div className="text">
                             <p>Stavropol State Medical University is a state higher educational institution in Stavropol city of the Russian Federation.</p>
                                </div>
                                <div className="link">
                                    <a href="https://thedoctorsiea.in/stavropol-state-medical-university-russia/" target="_blank" className="default_link">READ MORE <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                                
                        </div>

            </Figure>  
         </div>
          </Col>

</Slider>
          </Row>
       </Container>
       </section>

     
      </Fragment>
    )
  }
}

export default Services
