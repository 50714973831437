import React, { Component, Fragment } from 'react'
import { Col, Container, Figure, Row } from 'react-bootstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import f1 from '../../assets/images/1.jpg';
import f2 from '../../assets/images/2.jpg';
import f3 from '../../assets/images/3.jpg';

export class Features extends Component {
    constructor(props){
        super(props);
        this.next=this.next.bind(this);
        this.previous=this.previous.bind(this)
    }
    next(){
        this.slider.slickNext();
    }
    previous(){
        this.slider.slickPrev();
    }
  render() {

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed:3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
      <Fragment>
        <section className="features">
        <Container>
          <Row>
          <Slider ref={c=>(this.slider=c)} {...settings}>
          <Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="item">
           <Figure className="img-box">

           <a href="#"><img src={f1} className='img-fluid' alt="" /></a>        
           <div className="bottom-content">
            <h4>Learn Russian Language</h4>
            </div> 
            <div className="overlay-box">
                            <div className="inner-box">
                            <h4>Learn Russian Language</h4>
                                <div className="text">
                             <p>Study the Russian Language in Russia | Top Medical, Technical, Federal Universities of Russia | Doctors-IEA Study in Russia If...</p>
                                </div>
                                <div className="link">
                                    <a href={process.env.PUBLIC_URL + '/russian-language-course/'} className="default_link">READ MORE <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                                
                        </div>

            </Figure>  
         </div>
          </Col>

          <Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="item">
           <Figure className="img-box">

           <a href="#"><img src={f2} className='img-fluid' alt="" /></a>        
           <div className="bottom-content">
            <h4>Admission Process</h4>
            </div> 
            <div className="overlay-box">
                            <div className="inner-box">
                            <h4>Admission Process</h4>
                                <div className="text">
                             <p>Doctors-IEA provides complete guidance to students at every step. Please check the below documents list for applying for MBBS admission in...</p>
                                </div>
                                <div className="link">
                                    <a href={process.env.PUBLIC_URL + '/admission-process/'} className="default_link">READ MORE <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                                
                        </div>

            </Figure>  
         </div>
          </Col>
          
           <Col  key={1} xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="item">
           <Figure className="img-box">

           <a href="#"><img src={f3} className='img-fluid' alt="" /></a>        
           <div className="bottom-content">
            <h4>MBBS Fee Structure</h4>
            </div> 
            <div className="overlay-box">
                            <div className="inner-box">
                            <h4>MBBS Fee Structure</h4>
                                <div className="text">
                             <p>Fee structure for study MBBS in Russia | Medical college fee structure in Russia for MBBS | MBBS Fee for...</p>
                                </div>
                                <div className="link">
                                    <a href="https://thedoctorsiea.in/" className="default_link" target="_blank">READ MORE <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                                
                        </div>

            </Figure>  
         </div>
          </Col>

</Slider>
          </Row>
       </Container>
       </section>

     
      </Fragment>
    )
  }
}

export default Features
